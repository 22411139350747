var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-table',{ref:"tableBox",attrs:{"columns":_vm.columns,"show-search":true,"rowKey":"id","searchForm":_vm.searchForm,"toolActions":_vm.toolActions,"showToolBar":true,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"dataSourceApi":_vm.dataSourceApis,"dataSourceParams":_vm.dataSourceParams,"scroll":{ y: '55vh', x: '80vw' }},on:{"delect":_vm.del,"add":_vm.add,"action-column-click":_vm.actionColumnClick,"pop-confirm":_vm.popConfirm},scopedSlots:_vm._u([{key:"taskStatus",fn:function({ record }){return _c('div',{staticClass:"operName"},[_c('a-button',{class:{
          light_blue: record.taskStatus === '3' || record.taskStatus === '4', //已完成/已评价
          green: record.taskStatus === '2', //进行中
          gray: record.taskStatus === '5', //已取消/已拒绝
          blue: record.taskStatus === '0', //审核中//待审批
          orange: record.taskStatus === '1', //待派单
          red: record.taskStatus === '6',
        }},[_vm._v(" "+_vm._s(_vm.getStatusText(record.taskStatus))+" ")])],1)}},{key:"evaluate",fn:function({ record }){return _c('div',{staticClass:"operName"},[_c('a-rate',{attrs:{"default-value":record.evaluate,"allow-half":"","disabled":""}})],1)}}])}),_c('add-Settlement',{ref:"addSettlement",on:{"refsh":_vm.refsh}}),_c('choose-people',{ref:"choosePeople",on:{"choose":_vm.receiveDispatchId}}),_c('watch-settlement',{ref:"watchSettlement",on:{"refsh":_vm.refsh}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }