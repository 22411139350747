<!-- 居民点单表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      rowKey="id"
      :searchForm="searchForm"
      :toolActions="toolActions"
      @delect="del"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <!-- 点单状态 -->
      <div slot="taskStatus" slot-scope="{ record }" class="operName">
        <a-button
          :class="{
            light_blue: record.taskStatus === '3' || record.taskStatus === '4', //已完成/已评价
            green: record.taskStatus === '2', //进行中
            gray: record.taskStatus === '5', //已取消/已拒绝
            blue: record.taskStatus === '0', //审核中//待审批
            orange: record.taskStatus === '1', //待派单
            red: record.taskStatus === '6',
          }"
        >
          {{ getStatusText(record.taskStatus) }}
          <!-- {{ taskStatusText  }}  -->
          <!-- {{ record.taskStatus==='0'?'待审批':(record.taskStatus==='1'?'待接单':(record.taskStatus==='2'?'进行中':(record.taskStatus==='3'?'已完成':(record.taskStatus==='4'?'已评价':'已取消')))) }} -->
        </a-button>
      </div>
      <!-- 居民评单 -->
      <div slot="evaluate" slot-scope="{ record }" class="operName">
        <a-rate :default-value="record.evaluate" allow-half disabled />
      </div>
    </ax-table>
    <!-- 新增居民点单弹窗 -->
    <add-Settlement ref="addSettlement" @refsh="refsh"></add-Settlement>
    <!-- 派单弹窗 -->
    <choose-people
      ref="choosePeople"
      @choose="receiveDispatchId"
    ></choose-people>
    <!-- 居民点单详情弹窗 -->
    <watch-settlement ref="watchSettlement" @refsh="refsh"></watch-settlement>
  </div>
</template>

<script>
import addSettlement from "./addSettlement.vue";
import watchSettlement from "./watchSettlement.vue";
import choosePeople from "./choosePeople.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "点单人",
    type: "input",
    model: "residentName",
    options: { placeholder: "点单人" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "联系电话",
    type: "input",
    model: "residentPhone",
    options: { placeholder: "联系电话" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "点单状态",
    type: "select",
    model: "taskStatus",
    options: {
      placeholder: "请选择",
      options: [
        { label: "待审批", value: "0" },
        { label: "待接单", value: "1" },
        { label: "进行中", value: "2" },
        { label: "已完成", value: "3" },
        { label: "已评价", value: "4" },
        { label: "已取消", value: "5" },
      ],
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "点单类型", //从点单类型那里获取数据
    type: "select",
    model: "taskTypeId",
    options: {
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
// import WatchActivity from "./watchActivity.vue";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addSettlement, watchSettlement, choosePeople },
  data() {
    //这里存放数据
    return {
      api,
      // 表格模块数据
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "点单人",
            dataIndex: "residentName",
            ellipsis: true,
            width: 80,
          },
          {
            title: "性别",
            dataIndex: "gender",
            ellipsis: false,
            width: 60,
          },
          {
            title: "联系电话",
            dataIndex: "residentPhone",
            ellipsis: true,
            width: 120,
          },
          {
            title: "所在网格",
            dataIndex: "gridName",
            ellipsis: false,
            width: 120,
          },

          {
            title: "需求内容",
            dataIndex: "taskDescription",
            ellipsis: true, //超过自动变成省略号
            width: 100,
          },
          {
            title: "点单类型",
            dataIndex: "taskTypeName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "点单状态",
            dataIndex: "taskStatus",
            ellipsis: false,
            width: 120,
          },
          {
            title: "点单时间",
            dataIndex: "orderTime",
            ellipsis: false,
            width: 150,
          },
          {
            title: "居民评单",
            dataIndex: "evaluate",
            ellipsis: false,
            width: 150,
          },
        ],
        false, //去掉序号
        {
          // fixed: "right",
          width: 320,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "取消点单",
                  name: "cancelOrder",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: false,
                  title: "是否确定取消点单？",
                  disabled: record.taskStatus !== "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "派单",
                  name: "dispatch",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: true,
                  disabled: record.taskStatus !== "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "取消派单",
                  name: "cancelDispatch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: false,
                  title: "取消派单后，该点单需要重新派单，是否确认取消？",
                  disabled: record.taskStatus !== "2",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "确定删除该点单吗？",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [
        { name: "add", text: "新增" },
        { name: "dels", text: "批量删除",type: "#F95A5A" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.recordList,
      dataSourceParams: {},

      id: "", //表格数据id
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getStatusText(status) {
      const statusMap = {
        0: "待审批",
        1: "待接单",
        2: "进行中",
        3: "已完成",
        4: "已评价",
        5: "已取消",
        6: "已拒绝",
      };
      return statusMap[status];
    },
    // 接收子组件传过来的peopleId
    async receiveDispatchId(item) {
      console.log(item, "185");
      if (item.id) {
        const obj = {
          id: this.id,
          volunteerId: item.id,
          volunteerPhone: item.contactWay,
          taskStatus: "2",
          orderReceiveTime: new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),//接单时间
        };
        try {
          const res = await api.auditRecord(obj);
          console.log(res);
          if (res.status === 200) {
            this.$message.success("派单成功");
            this.$refs.tableBox.getDataSource();
          } else {
            this.$message.error("派单失败");
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            { options: { options, allowClear: true, placeholder: "请选择",changeOnSelect:true } }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 获取点单类型数据
    async getTaskTypeId() {
      const res = await api.getTypeList({ grade: 1 });
      console.log(res, "点单类型数据");
      const options = res.data.records.map((res) => {
        return { label: res.typeName, value: res.id };
      });
      this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
        "taskTypeId",
        { options: { options, allowClear: true, placeholder: "请选择" } }
      );
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 获取点单类型
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 派单
        case "dispatch":
          // record.title = "网格员派单";
          this.id = record.id;
          this.$refs.choosePeople.openModal({ title: "网格员派单" });
          break;
        // 详情
        case "watch":
          record.title = "居民点单详情";
          this.$refs.watchSettlement.openModal(record);
          break;
      }
    },
    // 根据id删除
    async popConfirm({ btn, record }) {
      console.log(btn.text);
      console.log(record);
      if (btn.text == "删除") {
        const res = await api.deleteRecordById(record.id);
        if (res.status === 200) {
          this.$message.success("删除成功");
          this.$refs.tableBox.getDataSource();
        }
      } else if (btn.text == "取消点单") {
        const obj = {
          id: record.id,
          volunteerId: "",
          volunteerPhone: "",
          taskStatus: "5",
        };
        try {
          const res = await api.auditRecord(obj);
          console.log(res);
          if (res.status === 200) {
            this.$message.success("取消点单成功");
            this.$refs.tableBox.getDataSource();
          } else {
            this.$message.error("取消点单失败");
          }
        } catch (err) {
          console.log(err);
        }
      } else if (btn.text == "取消派单") {
        const obj = {
          id: record.id,
          volunteerId: "",
          volunteerPhone: "",
          taskStatus: "1",
        };
        try {
          const res = await api.auditRecord(obj);
          console.log(res);
          if (res.status === 200) {
            this.$message.success("取消派单成功");
            this.$refs.tableBox.getDataSource();
          } else {
            this.$message.error("取消派单失败");
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.addSettlement.openModal({ title: "新增居民点单" });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteVolunteerActivityByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.owningGrid();
    this.getTaskTypeId();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.light_blue {
  background-color: #eaf1fa;
  color: #2d3138;
  border: #eaf1fa;
  border-radius: 4px;
}
.blue {
  background-color: rgba(42, 92, 255, 0.2);
  color: #2a5cff;
  border: rgba(42, 92, 255, 0.2);
  border-radius: 4px;
}
.orange {
  background-color: #fff8f0;
  color: #e6a23c;
  border: #fff8f0;
  border-radius: 4px;
}
.green {
  background-color: #ebf9e6;
  color: #67c23a;
  border-radius: 4px;
  border: #ebf9e6;
}
.gray {
  background-color: #e6e9ee;
  color: #2d3138;
  border-radius: 4px;
  border: #e6e9ee;
}
.red {
  background-color: #fff0f0;
  color: #f95a5a;
  border-radius: 4px;
  border: #fff0f0;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>